import React from 'react'
import '../css/intelligent-automation-usecase.scss'
import Layout from '../components/layout'
import Nav from '../components/nav/nav'
import ArrowRight from '../images/icons/arrow-right.svg'
import LibraryImg from '../images/intelligent-usecase/library.png'
import { Link } from 'gatsby'

const IntelligentAutomationUsecase = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="intelligent-usecase py-5">
                <div className="hero-section">
                    <div className="h-100 d-flex align-items-end">
                        <div className="container px-5">
                            <div className="row mb-5 pb-5 px-4">
                                <div className="col-md-7">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #E41700' }}></div>
                                        </div>
                                        <div className="col-md-11">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '3rem' }}><span className="text-red">Intelligent automation</span></h1>

                                            <p className="text-white mb-0" style={{ fontSize: '1.5rem' }}>Extend your business horizons with the amazing power of intelligent automation technologies.</p>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-1"></div>

                                <div className="col-md-4 my-auto">
                                    <p className="small off-white  mb-0" style={{ lineHeight: '22px' }}> Let us work with you to automate your end-to-end business operations, fusing intelligent automation technologies (AI and RPA) with human capabilities, to boost process efficiency, foster rapid resolution and improve customer experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5 pt-5">
                    <div className="my-md-5 py-md-5">
                        <h1 className="big-text text-center font-weight-bold mb-5 pb-5 mt-5 px-md-5 px-0">Here’s what we have <span className="text-red">done</span> for <span className="text-red">businesses</span> just like yours…</h1>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="proctorlaw-bg">
                                <img src={LibraryImg} alt="background" />
                            </div>

                            <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Process automation</span>Finance</p>

                            <Link to="/digitize-process-automation"><h4 className="cursor black-link-text" style={{lineHeight: '30px', fontWeight: '400'}}>Digitizing paper documents to flatten the COVID-19 curve</h4></Link>

                            <Link to="/digitize-process-automation"><p className="cursor black-link-text mb-0 pt-4 my-auto">Read Case study <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>
                        </div>
                    </div>
                </div>

            </div>

        </Layout>
    )
}

export default IntelligentAutomationUsecase
